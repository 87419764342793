.mat-icon.size-16px {
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  width: 16px;

  svg {
    width: 16px;
    height: 16px;
  }
}
