.mat-icon-button {
  .mat-button-wrapper {
    display: inline-block;
  }

  .mat-icon.mat-icon-inline {
    line-height: 1;

    svg {
      display: block;
      height: 1em;
      width: 1em;
      font-size: 1em;
    }
  }
}

.mat-button.button-with-icon {
  .mat-icon.mat-icon-inline {
    line-height: 1;
    margin-bottom: 2px;
    margin-right: 4px;

    svg {
      display: block;
      height: 1.125em;
      width: 1.125em;
      font-size: 1.125em;
    }
  }
}
