@import 'variables';

.mat-row {
  border: 1px solid $border-gray;
  transition: background-color 0.125s ease-in-out;

  &:hover {
    background-color: lighten($border-gray, 5%);
  }
}
