@import 'variables';

$colors: (
  'white': $white,
  'gray': $background-gray,
  'red-light': $red-light,
  'transparent': transparent,
);

@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: $color;
  }
}
