$padding-sizes: 0 4 8 16 24 36;

.p {
  @each $size in $padding-sizes {
    &-#{$size} {
      padding: #{$size}px;
    }
  }

  @each $size in $padding-sizes {
    &y-#{$size},
    &t-#{$size} {
      padding-top: #{$size}px!important;
    }

    &y-#{$size},
    &b-#{$size} {
      padding-bottom: #{$size}px!important;
    }

    &x-#{$size},
    &r-#{$size} {
      padding-right: #{$size}px!important;
    }

    &x-#{$size},
    &l-#{$size} {
      padding-left: #{$size}px!important;
    }
  }
}
