$margin-sizes: 0 4 8 16 24 48;

.m {
  @each $size in $margin-sizes {
    &-#{$size} {
      margin: #{$size}px;
    }
  }

  @each $size in $margin-sizes {
    &y-#{$size},
    &t-#{$size} {
      margin-top: #{$size}px;
    }

    &y-#{$size},
    &b-#{$size} {
      margin-bottom: #{$size}px;
    }

    &x-#{$size},
    &r-#{$size} {
      margin-right: #{$size}px;
    }

    &x-#{$size},
    &l-#{$size} {
      margin-left: #{$size}px;
    }
  }
}

.m-a {
  margin: auto;
}

.ml-a,
.mx-a {
  margin-left: auto;
}

.mr-a,
.mx-a {
  margin-right: auto;
}

.mt-a,
.my-a {
  margin-top: auto;
}

.mb-a,
.my-a {
  margin-bottom: auto;
}
